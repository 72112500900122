<template>
    <v-dialog content-class="search-layer bgBlurSearch" v-model="showSearchLayerVar" scrollable light
              transition="dialog-top-transition" width="100%" class="ma-0" style="margin:0 !important;"
    >
        <v-card class="noBackground">
            <v-toolbar dark class="search-layer-toolbar">
                <v-btn icon dark @click="handleShowSearchLayer(false)">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
                <v-text-field
                    class="searchfield"
                    ref="searchfield"
                    :append-icon-cb="() => {}"
                    :placeholder="$t('app.Search')"
                    color="white"
                    clearable
                    outlined
                    dense
                    v-model="searchFieldValue"
                    @keyup="checkSearchfield"
                    @input="onSearchFieldChange"
                    autofocus
                >
                    <v-icon>mdi-close</v-icon>
                </v-text-field>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn icon dark @click="doUpdateSearchFilter" v-on="onTooltip">
                            <v-icon>mdi-image-search-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.SearchLayer.search') }}</span>
                </v-tooltip>


            </v-toolbar>
            <!--<div class="chip-container" v-dragscroll>

              <span
                  v-for="(searchfilter, i) in searchfilters"
                  :key="i"
                  class="shrink"

              >
                  <v-chip
                      close
                      :color="chipColors[searchfilter.type]"
                      small
                      label
                      close-icon="mdi-close"
                      class="ma-1"
                      @click:close="removeChip(searchfilter)"
                  >
                      <v-icon
                          left
                      >{{ chipIcons[searchfilter.type] }}</v-icon>
                      <span class="chip-text">{{ searchfilter.text }}</span>
                  </v-chip>
              </span>
            </div>-->

            <v-card-text style="height: 100%;" class="pt-6">

                <v-expansion-panels accordion v-model="openSearchPanels" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-self-start" disable-icon-rotate>
                            <div>
                                <v-icon :color="chipColors['daterange']">{{ chipIcons['daterange'] }}</v-icon>
                                <span v-if="this.searchfilters.filter((item) => item.type === 'daterange').length==0">{{
                                        $t('app.SearchLayer.Daterange')
                                    }}</span>

                                <span v-else
                                      v-for="(searchfilter, i) in this.searchfilters.filter((item) => item.type === 'daterange')"
                                      :key="i"
                                      class="shrink"

                                >
                                  <v-chip
                                      close
                                      :color="chipColors[searchfilter.type]"
                                      small
                                      label
                                      close-icon="mdi-close"
                                      class="ma-1"
                                      @click:close="removeChip(searchfilter)"
                                  >{{ searchfilter.text }}
                                  </v-chip>
                              </span>


                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-range-slider
                                v-model="daterangeFilter"
                                :max="daterangeMax"
                                :min="daterangeMin"
                                thumb-label="always"
                                class="mt-3 daterange-slider"
                                @change="updateDaterangeFilter"
                                track-color="#DCD9D2"
                                :color="chipColors['daterange']"
                                outlined
                            >
                            </v-range-slider>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel >
                        <v-expansion-panel-header class="justify-self-start" disable-icon-rotate>
                            <div>
                                <v-icon :color="chipColors['category']">{{ chipIcons['category'] }}</v-icon>
                                <span v-if="this.searchfilters.filter((item) => item.type === 'category').length==0">{{ $t('app.Categories') }}</span>



                                <span v-else
                                      v-for="(searchfilter, i) in this.searchfilters.filter((item) => item.type === 'category')"
                                      :key="i"
                                      class="shrink"

                                >
                                  <v-chip
                                      close
                                      :color="chipColors[searchfilter.type]"
                                      small
                                      label
                                      close-icon="mdi-close"
                                      class="ma-1"
                                      @click:close="removeChip(searchfilter)"
                                  >{{ searchfilter.text }}
                                  </v-chip>
                              </span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense v-if="filteredCategories && filteredCategories.length>0">
                                <v-list-item-group>
                                    <v-list-item
                                        v-for="(item, i) in filteredCategories"
                                        :key="i"
                                        @click="addCategoryToFilters(item)"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <span class="text-caption centered" v-else >{{ $t('app.SearchLayer.categories_help') }}</span>

                        </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-self-start" disable-icon-rotate>
                            <div>
                                <v-icon :color="chipColors['place']">{{ chipIcons['place'] }}</v-icon>
                                <span v-if="this.searchfilters.filter((item) => item.type === 'place').length===0">{{ $t('app.Places') }}</span>

                                <span v-else
                                      v-for="(searchfilter, i) in this.searchfilters.filter((item) => item.type === 'place')"
                                      :key="i"
                                      class="shrink"

                                >
                                  <v-chip
                                      close
                                      :color="chipColors[searchfilter.type]"
                                      small
                                      label
                                      close-icon="mdi-close"
                                      class="ma-1"
                                      @click:close="removeChip(searchfilter)"
                                  >{{ searchfilter.text }}
                                  </v-chip>
                              </span>



                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense v-if="placesList && placesList.length>0">
                                <v-list-item-group>
                                    <v-overlay :value="geoDataLoading" :absolute="true">
                                        <v-progress-circular v-if="geoDataLoading" indeterminate
                                                             size="64"></v-progress-circular>
                                    </v-overlay>
                                    <v-list-item
                                        v-for="(item, i) in placesList"
                                        :key="i"
                                        :inactive="item.docsCount===0"
                                        @click="addPlaceToFilters(item)"
                                    >
                                        <v-list-item-content>

                                                <v-list-item-title>
                                                    <span v-if="item.streetAddress">{{item.streetAddress}}, </span>
                                                    <span v-if="item.addressLocality">{{item.addressLocality}}, </span>
                                                    <span v-if="item.addressRegion">{{item.addressRegion}}, </span>
                                                    <span> ({{ $t('app.PlaceTypes.'+item.type) }} in {{item.addressCountry.toUpperCase()}})</span>
                                                    <v-chip
                                                        class="ma-2"
                                                    >
                                                        {{item.docsCount}}
                                                    </v-chip>
                                                </v-list-item-title>


                                        </v-list-item-content>
                                        <v-list-item-action @click.stop.prevent="showOnMap(item)">
                                            <v-btn icon>
                                                <v-icon color="grey lighten-1">mdi-map-search</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <span class="text-caption centered" v-else >{{ $t('app.SearchLayer.places_help') }}</span>
                        </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-self-start" disable-icon-rotate>
                            <div>
                                <v-icon :color="chipColors['text']">{{ chipIcons['text'] }}</v-icon>
                                <span v-if="this.searchfilters.filter((item) => item.type === 'text').length===0">{{ $t('app.SearchLayer.Text') }}</span>

                                <span v-else
                                      v-for="(searchfilter, i) in this.searchfilters.filter((item) => item.type === 'text')"
                                      :key="i"
                                      class="shrink"

                                >
                                  <v-chip
                                      close
                                      :color="chipColors[searchfilter.type]"
                                      small
                                      label
                                      close-icon="mdi-close"
                                      class="ma-1"
                                      @click:close="removeChip(searchfilter)"
                                  >{{ searchfilter.text }}
                                  </v-chip>
                              </span>



                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="justify-center">
                            <v-list dense v-if="lastSearchRequests.length>0 && (searchFieldValue==null || searchFieldValue=='')">
                                <v-subheader> {{ $t('app.SearchLayer.last_search_requests') }}</v-subheader>
                                <v-list-item-group v-model="lastSearchRequests" color="primary">
                                    <v-list-item
                                        v-for="(item, i) in lastSearchRequests"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon v-text="item.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.text"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <span class="text-caption justify-center" v-else >{{ $t('app.SearchLayer.text_help') }}</span>
                        </v-expansion-panel-content>
                    </v-expansion-panel>


                </v-expansion-panels>




                <!--
                                <v-list dense>
                                    <v-subheader>
                                        <v-icon :color="chipColors['types']">{{chipIcons['types']}}</v-icon>
                                        {{ $t('app.SearchLayer.Types') }}
                                    </v-subheader>
                                    <v-container>
                                        <v-row no-glutters>
                                            <v-col cols="12" xs="1" sm="3" md="2" class="pa-0" :key="i"
                                                   v-for="(type, i) in types">
                                                <v-switch
                                                    v-model="typeFilter[type]"
                                                    :label="$t('app.SearchLayer.type_'+type)"
                                                    :value="typeFilter[type]"
                                                    @change="updateTypeFilter"
                                                    hide-details
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                </v-list>-->
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn @click="doUpdateSearchFilter" color="#FEE797" large block class="mb-4">
                    <v-icon>mdi-image-search-outline</v-icon>
                    Find
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style>
.search-layer-toolbar {
    height: auto !important;
}

.search-layer-toolbar .v-toolbar__content {
    padding: 0 16px !important;
    margin-top: max(30px, env(safe-area-inset-top, 30px)) !important;
}

.search-layer {
    margin: 0 !important;
    position: absolute;
    top: 0 !important;
    background-color: #DDDAD5;


}

.search-layer .col {
    padding: 2px;
}

.daterange-slider label {
    width: 70px;
}

.search-layer .v-toolbar__content {
    padding-right: 0;
}

.search-layer .chip-text {
    max-width: 110px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-layer .chip-container-main {
    width: calc(100% - 66px - 54px);
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    margin-top: 8px;
    position: absolute;
    right: -54px;
    left: 66px;

}

.search-layer .chip-container {
    /*width: calc(100% - 86px);*/
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    height: 54px;

}

/*
.search-layer .chip-container:before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
    width: 10px;
    height: 100%

}

.search-layer .chip-container:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
    width: 10px;
    height: 100%

}
*/

.searchfield {
    margin-top: 24px !important;
}
.bgBlurSearch{
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.3) !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-bottom: 1px solid rgba(255,255,255,0.3) !important;

}

</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import fetch from '../../utils/fetch'
import { ENTRYPOINT } from '@/config/entrypoint'

export default {
    directives: {
        'dragscroll': dragscroll
    },
    props: {
        showSearchLayer: {
            type: Boolean,
            required: true
        },
        handleShowSearchLayer: {
            type: Function,
            required: true
        },
        searchfiltersParam: {
            type: Array,
            required: true
        },
        updatesearchfilters: {
            type: Function,
            required: true
        }

    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            searchparams: {},
            lastSearchRequests: [],
            searchFieldValue: '',
            filteredCategories: [],
            placesList: [],
            autocomplete: null,
            searchfilters: this.searchfiltersParam,
            chipIcons: {
                'text': 'mdi-card-text',
                'category': 'mdi-view-list',
                'place': 'mdi-map-marker',
                'daterange': 'mdi-calendar-range',
                'types': 'mdi-file-multiple'
            },
            chipColors: {
                'text': '#8C8189',
                'category': '#9E919A',
                'place': '#B0A0AA',
                'daterange': '#C2B0BC',
                'types': '#989181'
            },
            types: [
                'ImageGallery', 'PhotographCollection', 'Photograph', 'Retrosnap'
            ],
            openSearchPanels: [],
            daterangeMin: 1850,
            daterangeMax: 1980,
            daterangeFilter: [1850, 1980],
            typeFilter: {
                'ImageGallery': true,
                'PhotographCollection': true,
                'Photograph': true,
                'Retrosnap': true,
                'Person': true,
                'Comment': true
            },
            geoDataLoading: false,
            loadGeoDataTimeout: null,
            showSearchLayerVar: false

        }
    },
    computed: {
        ...mapGetters({
            categories: 'category/list/items',
        })
    },
    watch: {
        // Everytime the route changes, check for auth status
        //'$route': 'isAuthenticated',
        searchfiltersParam (newValue) {
            this.searchfilters = newValue
        },
        showSearchLayer (newValue) {
            this.showSearchLayerVar = newValue
        },
        showSearchLayerVar(newValue){
            this.handleShowSearchLayer(newValue)
        }

    },
    mounted () {

        this.getCategories()
    },

    methods: {
        ...mapActions({
            getCategories: 'category/list/default'
        }),
        geolocate: function () {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
            })
        },

        getTranslation (item) {
            let transList = item.translations.filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item.translations[0] : transList[0]
        },
        checkSearchfield (e) {
            window.console.log(e)
            if (e.keyCode === 13) {
                this.doUpdateSearchFilter();
            }
        },
        onSearchFieldChange () {
            if (this.searchFieldValue === '' || this.searchFieldValue === null) {
                this.filteredCategories = []
            } else {
                this.geoDataLoading = true
                if (this.loadGeoDataTimeout !== null) clearTimeout(this.loadGeoDataTimeout)
                this.loadGeoDataTimeout = setTimeout(() => {this.loadGeoData()}, 500)

            }
        },
        loadGeoData () {
            if (this.searchFieldValue.length>3){
                this.filteredCategories = this.categories.filter((category) => category.name.toLowerCase().indexOf(this.searchFieldValue.toLowerCase()) !== -1)
            }else{
                this.filteredCategories = [];
            }

            if (this.filteredCategories.length > 0) this.openSearchPanels = [1]
            return fetch(this.entrypoint + '/placesSearchWithDocs?q=' + this.searchFieldValue, {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    this.placesList = data
                    this.geoDataLoading = false
                    if (this.placesList.length > 0) this.openSearchPanels.push(2)
                })
                .catch((e) => {
                    window.console.error('Geocode was not successful for the following reason: ' + e)
                    this.geoDataLoading = false
                    return e
                })
        },
        setTextFilter() {
            this.searchfilters = this.searchfilters.filter((item) => (item.type !== 'text' && item.type !== 'geo'))
            if (this.searchFieldValue.length > 0) {
                this.searchfilters.push({
                    text: this.searchFieldValue,
                    type: 'text',
                    data: {},
                    queryParam: 'combined=' + this.searchFieldValue
                })
            }
            this.searchFieldValue = ''
            //this.updatesearchfilters(this.searchfilters);
        },
        addCategoryToFilters (item) {
            this.searchfilters = this.searchfilters.filter((item) => (item.type !== 'category' && item.type !== 'geo'))
            this.searchfilters.push({
                text: item.name,
                type: 'category',
                data: {id: item.id},
                queryParam: 'categories[]=' + item.id
            })
            //this.updatesearchfilters(this.searchfilters);
            this.searchFieldValue = '';
            this.doUpdateSearchFilter();
        },
        addPlaceToFilters (item) {
            if (item.docsCount === 0) return

            this.searchfilters = this.searchfilters.filter((item) => (item.type !== 'place' && item.type !== 'geo'))

            let queryParam = []
            queryParam.push('placeKeys=' + item.id.toUpperCase())
            let placeName = item.addressRegion;
            if(item.addressLocality) placeName = item.addressLocality;
            if(item.streetAddress) placeName = item.streetAddress;

            this.searchfilters.push({
                text: placeName,
                type: 'place',
                data: item,
                queryParam: queryParam.join('&')
            });
            this.searchFieldValue = ''
            this.doUpdateSearchFilter();

        },
        updateDaterangeFilter () {
            this.searchfilters = this.searchfilters.filter((item) => (item.type !== 'daterange' && item.type !== 'geo'))
            this.searchfilters.push({
                text: this.daterangeFilter[0] + '-' + this.daterangeFilter[1],
                type: 'daterange',
                data: {fromDate: this.daterangeFilter[0], toDate: this.daterangeFilter[1]},
                queryParam: 'yearFilter=' + this.daterangeFilter[0] + ',' + this.daterangeFilter[1]
            })
            //this.updatesearchfilters(this.searchfilters);
        },
        updateTypeFilter () {
            this.searchfilters = this.searchfilters.filter((item) => (item.type !== 'type' && item.type !== 'geo'))
            let activeTypes = []
            Object.keys(this.typeFilter).forEach((key) => {
                if (this.typeFilter[key] === true) activeTypes.push(key)
            })
            if (activeTypes.length === 0 || activeTypes.length === this.typeFilter.length) {
                return false
            }

            let queryParam = activeTypes.join('&documentType[]=')
            //this.searchfilters.push({text:this.searchFieldValue,type:'text',data:{},queryParam:'combined='+this.searchFieldValue});
            //this.searchfilters.push({text:activeTypes.join(', '),type:'type',data:this.typeFilter,queryParam:'type[]='+queryParam});
            this.searchfilters.push({
                text: activeTypes.join(', '),
                type: 'type',
                data: JSON.parse(JSON.stringify(this.typeFilter)),
                queryParam: 'documentType[]=' + queryParam
            })

            //this.updatesearchfilters(this.searchfilters);
        },
        removeChip (searchfilter) {
            this.searchfilters = this.searchfilters.filter((item) => item !== searchfilter)
            //this.updatesearchfilters(this.searchfilters);
        },
        showOnMap (item) {
            this.$router.push({name: 'MapShowGeo', params: {geo: item.geo.lat+','+item.geo.lon}})
            this.handleShowSearchLayer(false)
            //window.console.log(item)
        },
        doUpdateSearchFilter () {
            if (this.searchFieldValue.length > 0) {
                this.setTextFilter();
            }
            this.searchfilters.sort((a, b) => {
                if (a.type < b.type) {
                    return -1
                }
                if (a.type > b.type) {
                    return 1
                }
                return 0
            })
            this.updatesearchfilters(this.searchfilters)
            this.handleShowSearchLayer(false)
        }

    }
}
</script>
