<template>
    <v-container pa-1 fluid id="homecontainer">
        <!--<v-row >-->
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deletedItem"
            v-model="deletedItem"
            :timeout="5000"
            :top="true"
            color="success"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deletedItem['@id'] }} deleted.
        </v-snackbar>

        <div class="masonry-wrapper mt-13">
            <div class="filter-bar">
                <div v-show="shouldShowBackButton" class=" back-svg" @click="goBack">
                    <svg width="50px" height="50px" viewBox="0 0 47 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;fill: white; transform: rotate(180deg);">
                         <path d="M11.032,2.569l30.616,30.616l-30.616,30.616l0,-61.232Z"/>
                    </svg>
                </div>
                <SearchFilter></SearchFilter>
            </div>

            <v-slide-y-transition >
                <v-card  v-if="showCreateAlbumForSearchDialog"
                         color="#82837B"
                         dark
                         class="mb-2"
                >
                    <v-card-title>
                        <span>{{ $t('app.CreateAlbumForSearchParamsTitle')}}</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            rounded
                            small
                            icon
                            @click="showCreateAlbumForSearchDialog=false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-subtitle v-text="$t('app.CreateAlbumForSearchParamsText')"></v-card-subtitle>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ml-2 mt-5"
                            outlined
                            rounded
                            small
                            :to="{name: 'ImageGalleryCreateSearchparameters'}"
                        >
                            {{ $t('app.CreateAlbumForSearchParamsButton')}}
                        </v-btn>

                    </v-card-actions>

                </v-card>
            </v-slide-y-transition>
            <div v-if="!searchfilters.length>0">
                <landing-buttons v-if="!isMobile" :displayColumn="false"/>
                <mobile-landing-buttons v-else />
            </div>
            <div class="masonry"
                v-scroll="onScroll"
            >
                <v-slide-y-transition >
                <v-card  v-if="closedCards.indexOf('cardid_0')===-1"
                    color="#C6BBB7"
                    dark
                    class="mb-2 .mi-big"
                >
                    <v-card-title
                        class="text-h5"
                    >
                        <span>3D RetroPics</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            rounded
                            small
                            icon
                            @click="closedCards.push('cardid_0')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>


                            <v-card-subtitle v-text="'RetroPics mit Tiefeninformationen. Mit der neuen Funktion wird aus RetroPics eine Art „Wackelbild“, die einen stereoskopischen 3D-Effekt haben.'"></v-card-subtitle>


                        </div>

                        <v-avatar
                            class="ma-3"
                            size="125"
                            tile
                        >
                            <v-img src="https://api.nimmerso.app/image_galleries/6041ed87b4396630111b60d2.21/small.jpeg"></v-img>
                        </v-avatar>
                    </div>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="ml-2 mt-5"
                                outlined
                                rounded
                                small
                                :to="{name: 'ImageGalleryShow', params: {id: decodeURIComponent('%2Fimage_galleries%2F6041ed87b4396630111b60d2')}}"
                            >
                                zum Album
                            </v-btn>

                        </v-card-actions>

                </v-card>

                </v-slide-y-transition>

                <div
                    :class="'masonry-item'+getRatioClass(item)"
                    v-for="(item, index) in allItems"
                    :key="index.id"

                >
                    <v-slide-y-transition v-if="item.documentType==='InfoCard'" >
                        <v-card  v-if="closedCards.indexOf('cardid_'+item.id)===-1"
                                 :color="item.color ? item.color : '#C6BBB7'"
                                 dark
                                 class="mb-2 info-card"
                                 :to="item.link"
                        >
                            <video v-if="item.video" autoplay muted loop playsinline>
                                <source :src="item.video" type="video/mp4">
                            </video>


                        </v-card>
                    </v-slide-y-transition>
                    <v-img v-if="item.documentType==='ImageGallery' || item.documentType==='PhotographCollection' "
                           class=" masonry-content"
                           :aspect-ratio="1"
                           :src="entrypoint+item.images[0]+'/list'+(webpSupported ? '.webp' : '.jpeg')"
                           :lazy-src="entrypoint+item.images[0]+'/lazy'+(webpSupported ? '.webp' : '.jpeg')"
                           @dblclick.stop="carouselItemClick(item)"
                           @click.stop="carouselItemClick(item)"
                           @load="rellaxRefresh()"
                    >
                    </v-img>
                    <v-img v-if="item.documentType==='Photograph' && (item.images.length>1 || !item.depthMapPath || !show25DPhotos)"
                           v-touch="{ left: () => setActiveTouchEle(item),  right: () => setActiveTouchEle(item), up: () => setActiveTouchEle(item), down: () => setActiveTouchEle(item)}"
                           class="masonry-content"
                           :aspect-ratio=" (item.thumbnailCoordinates && item.thumbnailCoordinates.feed) ? item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height : item.imageRatio"
                           :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+item.images[0]+(webpSupported ? '.webp' : '.jpeg')+
                               (item.thumbnailCoordinates && item.thumbnailCoordinates.feed ? '?c='+item.thumbnailCoordinates.feed.left+','+item.thumbnailCoordinates.feed.top+','+item.thumbnailCoordinates.feed.width+','+item.thumbnailCoordinates.feed.height+',' : '')"
                           :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+item.images[0]+'.jpeg'+
                               (item.thumbnailCoordinates && item.thumbnailCoordinates.feed ? '?c='+item.thumbnailCoordinates.feed.left+','+item.thumbnailCoordinates.feed.top+','+item.thumbnailCoordinates.feed.width+','+item.thumbnailCoordinates.feed.height+',' : '')"
                           @dblclick.stop="carouselItemClick(item)"
                           @click.stop="carouselItemClick(item)"
                    >
                        <v-img v-if="item.images.length>1"
                               :class="item.images.length>1 ? 'retrosnapImg fadingEffect' : ''"
                               :aspect-ratio=" (item.thumbnailCoordinates && item.thumbnailCoordinates.feed) ? item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height : item.imageRatio"
                               :src="(item.images.length > 1 ? entrypoint+'/thumbnail/list/'+item.images[item.images.length-1]+(webpSupported ? '.webp' : '.jpeg') : entrypoint+'/thumbnail/list/'+item.images[0]+(webpSupported ?'.webp' : '.jpeg'))+
                               (item.thumbnailCoordinates && item.thumbnailCoordinates.feed ? '?c='+item.thumbnailCoordinates.feed.left+','+item.thumbnailCoordinates.feed.top+','+item.thumbnailCoordinates.feed.width+','+item.thumbnailCoordinates.feed.height+',' : '')"
                               :lazy-src="(item.images.length>1 ? entrypoint+'/thumbnail/lazy/'+item.images[item.images.length-1]+'.jpeg' : entrypoint+'/thumbnail/lazy/'+item.images[0]+'.jpeg')+
                               (item.thumbnailCoordinates && item.thumbnailCoordinates.feed ? '?c='+item.thumbnailCoordinates.feed.left+','+item.thumbnailCoordinates.feed.top+','+item.thumbnailCoordinates.feed.width+','+item.thumbnailCoordinates.feed.height+',' : '')"
                        ></v-img>

                    </v-img>

                    <Viewer25D
                        v-if="item.documentType==='Photograph' && item.images.length===1 && item.depthMapPath && show25DPhotos"
                        :imageOriginal="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+item.images[0]+'.jpeg'"
                        :imageLazy="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+item.images[0]+'.jpeg'"
                        :imageDepth="entrypoint+'/thumbnail/list/'+item.depthMapPath"
                        :aspectRatio="getRatioRounded(item)"
                        :horizontalThreshold="45"
                        :verticalThreshold="45"></Viewer25D>
                    <div v-if="item.documentType==='Photograph'" class="retrosnapOverlay"
                         @dblclick.stop="carouselItemClick(item)"
                         @click.stop="carouselItemClick(item)"
                         v-touch="{ left: () => setActiveTouchEle(item),  right: () => setActiveTouchEle(item), up: () => setActiveTouchEle(item), down: () => setActiveTouchEle(item)}"
                    >
                        <v-row justify="center"
                               v-if="showInfosOverlay"
                               transition="fade-transition"
                        >
                            <v-card-title class="homevcardtitle fill-height align-left"
                                          v-text="getTranslation(item).name"
                            ></v-card-title>

                        </v-row>
                        <!--<v-card-text class="homevcarddescription"
                                     v-if="showInfosOverlay"
                                     transition="fade-transition"
                                     v-html="getTranslation(item).description"
                        >
                        </v-card-text>-->
                        <v-card-actions
                            v-if="showInfosOverlay"
                            transition="fade-transition"
                            class="homevcardactions">
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn @click.native.stop.prevent="showGallerySelectDialog(item)" icon v-on="{...onTooltip}" ><v-icon>mdi-notebook-plus</v-icon></v-btn>
                                </template>
                                <span>{{ $t('app.Gallery.Add') }}...</span>
                            </v-tooltip>
                            <v-btn v-if="item.images.length>1" @click.native.stop.prevent=""
                                   :to="{name: 'PhotographRetrosnaps'+ (showColorized ? 'Color' : ''), params: {id: item['id']}}" icon>
                                <v-icon>icon-RetroSnap</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </div>
                    <div v-if="item.hasBackImage===true" class="flipsideIcon">
                        <img style="width:32px;height:32px" src="/img/icons/FlipSide_Available_complete.svg"  alt="flip"/>
                    </div>
                </div>
            </div>

        </div>
        <div id="homecontainerbottom" v-if="view">
            <div v-if="isLoading || view['hydra:next']" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="brown"
                    class="center bottom"
                />
            </div>
        </div>

        <v-dialog v-model="gallerySelectDialog" max-width="400">
            <v-card>
                <v-card-title>{{ $t('app.Gallery.Add')}}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        value=""
                        :label="$t('app.Gallery.Select')"
                        :items="imagegalleries"
                        item-value="@id"
                        item-text="headline"
                        v-model="selectedGallery"
                        dense
                        filled
                        :placeholder="$t('app.Gallery.Select')"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar
                                    tile
                                    size="64"
                                >
                                    <v-img
                                        :src="entrypoint+'/image_galleries/'+data.item['thumbnail']+'/icon'"

                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.headline"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :to="{ name: 'ImageGalleryCreate' }"
                        color="secondary"
                        plain
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('app.Gallery.Create') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        text
                        @click="selectGalery"
                    >
                        {{$t('app.Gallery.AddShort')}}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <!--</v-row>-->
    </v-container>

</template>
<style>
.filter-bar{
    display: flex;
    justify-content: space-between;
}
.back-svg{
    width: 50px;
    cursor: pointer;
}
    .homevcardactions {
        padding: 0;
        bottom: 10px;
        position: absolute;
    }

    .homevcardtitle {
        text-shadow: 0 1px 2px black;
        text-overflow: ellipsis;
        font-family: "Roboto Condensed", sans-serif;
    }

    .homevcardactions i {
        text-shadow: 0 1px 2px black;
    }

    .homevcarddescription {
        text-shadow: 0 1px 2px black;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    /*
    .collectiontitle {
        margin-top: 30%;
        position: static;
        background-color: black;
        display: inline-block;
        margin-left: 0;
        box-shadow: 0 1px 2px black;
    } */

    .masonry {

        display: grid;
        grid-gap: 4px;
        grid-auto-rows: 0.5fr;
        /*grid-template-columns: repeat(2, 1fr);
        grid-auto-flow:dense;*/
        counter-reset: albumList;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        grid-auto-flow: row dense;
    }

    .masonry-wrapper {
        margin-right: auto;
        margin-left: auto;
        image-rendering: optimizeQuality;
    }


    .masonry-item {
        position: relative;
        background: #ccc;
        display:flex;
    }

    .info-card {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .masonry-item video {
        position: absolute;
        left: 50%; /* % of surrounding element */
        top: 50%;
        transform: translate(-50%, -50%); /* % of current element */
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        height: 100%;
    }


    /* Square */
    .masonry-item:after {
        content: '';
        padding-top: 100%;
    }

    /*zu checken: */

    .masonry-item, .masonry-content {
        border-radius: 4px;
        overflow: hidden;
    }

    .masonry-item {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .3));
        transition: filter .25s ease-in-out;
        background-color: #010308;
    }

    .masonry-item:hover {
        filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .3));
    }

    .masonry-footer a {
        color: currentColor;
    }

    .mi-auto{
        grid-row: 1 / -1;
        grid-column: 1 / -1;
    }
    .mi-big{
        grid-row:span 2;
        grid-column:span 2;
    }
    .mi-small{
        grid-row:span 1;
        grid-column:span 1;
    }
    .mi-horizontal {
        grid-row:span 1;
        grid-column:span 2;
    }
    .mi-vertical {
        grid-row:span 2;
        grid-column:span 1;
    }
    .mi-vertical div{

    }



    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        /* 1024+ */
        .masonry {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media print, screen and (min-width: 64em) and (max-width: 87.99875em) {
        /* 1024+ */
        .masonry {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media print, screen and (min-width: 88em) and (max-width: 111.99875em) {
        .masonry {
            grid-template-columns: repeat(5, 1fr);
        }
    }
    @media print, screen and (min-width: 112em) and (max-width: 135.99875em)  {
        .masonry {
            grid-template-columns: repeat(6, 1fr);
        }
    }
    @media print, screen and (min-width: 136em) and (max-width: 159.99875em)  {
        .masonry {
            grid-template-columns: repeat(7, 1fr);
        }
    }
    @media print, screen and (min-width: 160em) and (max-width: 183.99875em)  {
        .masonry {
            grid-template-columns: repeat(8, 1fr);
        }
    }
    @media print, screen and (min-width: 184em) and (max-width: 207.99875em)  {
        .masonry {
            grid-template-columns: repeat(9, 1fr);
        }
    }
    @media print, screen and (min-width: 208em) and (max-width: 231.99875em)  {
        .masonry {
            grid-template-columns: repeat(10, 1fr);
        }
    }
    @media print, screen and (min-width: 232em) and (max-width: 255.99875em)  {
        .masonry {
            grid-template-columns: repeat(11, 1fr);
        }
    }
    @media print, screen and (min-width: 256em) and (max-width: 279.99875em)  {
        .masonry {
            grid-template-columns: repeat(12, 1fr);
        }
    }
    @media print, screen and (min-width: 280em) and (max-width: 303.99875em)  {
        .masonry {
            grid-template-columns: repeat(13, 1fr);
        }
    }
    @media print, screen and (min-width: 304em)  {
        .masonry {
            grid-template-columns: repeat(14, 1fr);
        }
    }

    /** zu ckecken ende */

    @-moz-keyframes showHide { /* FF */
        0% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
    }

    @-ms-keyframes showHide { /* IE10 */
        0% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
    }

    @-o-keyframes showHide { /* Opera */
        0% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
    }

    @keyframes showHide {
        0% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
    }

    .retrosnapOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .retrosnapImg {
        /*position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background: black;*/
        width:100%;
        height:100%;
    }

    .fadingEffect {
        -moz-animation: showHide 10s ease-in-out alternate infinite; /* Firefox */
        -webkit-animation: showHide 10s ease-in-out alternate infinite; /* Safari and Chrome */
        -ms-animation: showHide 10s ease-in-out alternate infinite; /* IE10 */
        -o-animation: showHide 10s ease-in-out alternate infinite; /* Opera */
        animation: showHide 10s ease-in-out alternate infinite;
    }

    /*.fadingEffect .v-image__image {
        position: fixed;
    }*/

    .flipsideIcon{
        position: absolute;
        bottom: -10px;
        right: -1px;

    }

</style>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import { ENTRYPOINT } from '@/config/entrypoint'
    import { Scroll, Touch } from 'vuetify/lib/directives'
    import fetch from '../../utils/fetch'
    import isWebpSupported from '../../utils/isWebpSupported'
    import SearchFilter from '../search/SearchFilter'
    import Rellax from 'rellax'
    import Viewer25D from '../viewer25d/Viewer25D';
    import LandingButtons from "@/components/landing/LandingButtons.vue";
    import MobileLandingButtons from "@/components/landing/MobileLandingButtons.vue";
    export default {
        components: {MobileLandingButtons, SearchFilter, Viewer25D,LandingButtons},
        directives: {
            Scroll,
            Touch
        },
        computed: {
            ...mapGetters({
                deletedItem: 'feeditem/del/deleted',
                error: 'feeditem/list/error',
                items: 'feeditem/list/items',
                isLoading: 'feeditem/list/isLoading',
                view: 'feeditem/list/view',
                totalItems: 'feeditem/list/totalItems',
                //imagegalleries: 'imagegallery/list/items',
                //personError: 'person/show/error',
                //personIsLoading: 'person/show/isLoading',
                personItem: 'person/show/retrieved',
                searchfilters: 'searchfilters/items',
                settings: 'settings/items',
                token: 'user/token/token',
                refresh_token: 'user/token/refresh_token',
                token_data: 'user/token/token_data'
            }),
            shouldShowBackButton() {
                // The button should be shown if there are search filter
                return this.searchfilters.length > 0
            }

        },
        watch: {
            items: function () {

                let itemsSorted=[...this.items];
                /*itemsSorted.push({
                    documentType: 'InfoCard',
                    id: 'videotestcard_'+this.allItems.length,
                    video: '/videos/Borkum_Juni_1925_0.mp4',
                    color:'#000000',
                    link: '/report_collection',
                    miClass: 'small' //this.cardsCounter % 2 === 1 ? 'small' : 'big'
                })*/
                itemsSorted.sort((a, b) => {
                    let sizeSortA=this.getSizeSort(a);
                    let sizeSortB=this.getSizeSort(b);
                    if(sizeSortA < sizeSortB){
                        return 1;
                    }
                    if(sizeSortA > sizeSortB){
                        return -1;
                    }
                    return 0;
                });

                this.allItems.push(...itemsSorted)

                this.cardsCounter++;
                this.allItems = Array.from(new Set(this.allItems.map(a => a.id)))
                    .map(id => {
                        return this.allItems.find(a => a.id === id)
                    })
                if (window.IntersectionObserver) {
                    let observer = new IntersectionObserver((entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                entry.target.classList.add('fadingEffect')
                            } else {
                                entry.target.classList.remove('fadingEffect')
                            }
                        })
                    }, {rootMargin: '0px 0px 0px 0px'})
                    document.querySelectorAll('.retrosnapImg').forEach(img => {
                        observer.observe(img)
                    })
                }
                /*
                Create Album for search results Dialog derweil deaktiviert:
                if (this.totalItems>2 && this.totalItems < 50 && this.searchfilters.length>0 && this.albumsForSearchParams===0){
                    this.showCreateAlbumForSearchDialog=true;
                }else{
                    this.showCreateAlbumForSearchDialog=false;
                }*/
                setTimeout(() => {
                    if (this.rellax == null || (typeof this.rellax == 'object' && typeof this.rellax.refresh == 'undefined')) {
                        //console.log('new rellax');
                    } else {
                        //console.log(typeof this.rellax);
                        this.rellax.destroy()
                    }
                    this.rellax = new Rellax('.rellax', {
                        //center: true,
                        round: true
                    })
                    window.rellax = this.rellax
                }, 1000)
            },
            personItem: function () {
                this.imagegalleries = [...this.personItem.imageGalleries];
                this.imagegalleries.sort(function(a,b){
                    const nameA = a.headline.toUpperCase() // Groß-/Kleinschreibung ignorieren
                    const nameB = b.headline.toUpperCase() // Groß-/Kleinschreibung ignorieren
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // Namen müssen gleich sein
                    return 0;
                })
            },
            searchfilters () {
                window.console.log('watcher searchfilters in Homelist')
                this.allItems = []
                this.getPage('/feed_items?' + this.getParamsFromSearchfilters())
                if (this.searchfilters.length>0){
                    //window.console.log(this.searchfilters);
                    let params = []
                    this.searchfilters.forEach((item) => {
                        params.push(item.queryParam)
                    })
                    this.getAlbumForSearchParams( params.join('&'));
                }
            },
            albumsForSearchParams(){
                /* derweil deaktiviert:
                if (this.totalItems>2 && this.totalItems < 50 && this.searchfilters.length>0 && this.albumsForSearchParams===0){
                    this.showCreateAlbumForSearchDialog=true;
                }else{
                    this.showCreateAlbumForSearchDialog=false;
                }

                 */
            },
            settings () {
                this.showColorized = this.settings.showColorized
                this.showInfosOverlay = this.settings.showInfosOverlay
                this.show25DPhotos = typeof this.settings.show25DPhotos != 'undefined' ? this.settings.show25DPhotos: true;
            }
        },
        data: () => ({
            entrypoint: ENTRYPOINT,
            showImage: false,
            selectedImage: null,
            zoomFactor: 100,
            allItems: [],
            imagegalleries: [],
            showColorized: false,
            showInfosOverlay: false,
            show25DPhotos: true,
            rellax: null,
            activeTouchEle: null,
            //token: {}
            webpSupported: true,
            closedCards: ['cardid_0'],
            showCreateAlbumForSearchDialog: false,
            albumsForSearchParams: null,
            cardsCounter: 0,
            selectedGallery: null,
            gallerySelectDialog: false,
            selectedSlide: null,
            isMobile: false,
        }),
        mounted() {
            // Initialize screen size check on mount
            this.checkMobileView();
            // Listen for window resize event
            window.addEventListener('resize', this.checkMobileView);
        },
        beforeDestroy() {
            // Clean up event listener before component is destroyed
            window.removeEventListener('resize', this.checkMobileView);
        },
        created () {

            this.webpSupported=isWebpSupported();

            /*navigator.geolocation.getCurrentPosition(position => {

                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                    this.$offlineStorage.set('lastPosition', this.center)
                    this.getPage('/feed_items?' + this.getParamsFromSearchfilters())
                    //this.getImageGalleries('/image_galleries');

                    if (this.token_data) {
                        this.personRetrieve('/people/' + this.token_data.person_id)
                    } else {
                        window.console.log('no tokenData!')
                    }
                },
                () => {
                    const position = this.$offlineStorage.get('lastPosition')
                    if (position) {
                        this.center = position
                    } else {
                        this.center = {
                            lat: null,
                            lng: null
                        }
                    }
                    this.getPage('/feed_items?' + this.getParamsFromSearchfilters())
                    if (this.token_data) {
                        this.personRetrieve('/people/' + this.token_data.person_id)
                    } else {
                        window.console.log('no tokenData!')
                    }
                })*/
            this.getPage('/feed_items?' + this.getParamsFromSearchfilters())
            if (this.searchfilters.length>0){
                let params = []
                //window.console.log(this.searchfilters);
                this.searchfilters.forEach((item) => {
                    params.push(item.queryParam)
                })
                this.getAlbumForSearchParams( params.join('&'));
            }
            if (this.token_data) {
                this.personRetrieve('/people/' + this.token_data.person_id)
            } else {
                window.console.error('no tokenData!')
            }
            this.showColorized = this.settings.showColorized
            this.showInfosOverlay = this.settings.showInfosOverlay
            this.show25DPhotos = this.settings.show25DPhotos;
        },

        methods: {
            ...mapActions({
                getPage: 'feeditem/list/default',
                //getImageGalleries: 'imagegallery/list/default',
                updateImageGallery: 'imagegallery/update/update',
                updateRetrievedImageGallery: 'imagegallery/update/updateRetrieved',
                retrieveImageGallery: 'imagegallery/update/retrieve',
                personRetrieve: 'person/show/retrieve',
                gettokendata: 'user/token/gettokendata',
                removeSearchfilters: 'searchfilters/removeSearchfilters'
            }),
            goBack() {
                if (this.searchfilters.length>0){
                    this.removeSearchfilters()
                }else{
                    this.$router.back()
                }
            },
            checkMobileView() {
                // Check if the window width is less than 768px
                this.isMobile = window.innerWidth <= 768;
            },
            getTranslation (item) {
                let transList = item.translations.filter((t) => t.locale === this.$root.$i18n.locale)
                return typeof transList[0] === 'undefined' ? item.translations[0] : transList[0]
            },
            zoom (image) {
                this.showImage = true
                this.selectedImage = image
                this.zoomFactor = 100
            },
            carouselItemClick (item) {
                //window.console.log(item);
                let documentType = item['documentType']
                //if (documentType === 'PhotographCollection') documentType = 'ImageGallery'
                //if(documentType === 'Photograph' && item['images'].length>1){
                //    this.$router.push({name: 'PhotographRetrosnaps', params: {id: decodeURIComponent(item['id'])}})
                //}else{
                this.$router.push({name: documentType + 'Show'+ (this.showColorized ? 'Color' : ''), params: {id: decodeURIComponent(item['id'])}})
                //}

            },
            onScroll () {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 2000

                if (bottomOfWindow) {
                    if (this.view && this.view['hydra:next'] && !this.isLoading) this.getPage(this.view['hydra:next'])
                }
            },
            getParamsFromSearchfilters () {
                let params = [/*'itemsPerPage=6',*/'page=1']
                let hasGeo = false
                let hasFulltext = false
                this.searchfilters.forEach((item) => {
                    if (item.type === 'place') hasGeo = true
                    if (item.type === 'geo') hasGeo = true
                    if (item.type === 'text') hasFulltext = true
                    params.push(item.queryParam)
                })
                if(this.searchfilters.length===0){
                    params.push('showInFeed=true')
                }
                if (!hasFulltext && !hasGeo) params.push('order[computedRating]=DESC')
                //if (!hasgeo && this.center.lat && this.center.lng) params.push('geo=' + this.center.lat + ',' + this.center.lng);
                return params.join('&')
            },
            addToGallery (imagegallery, slide) {
                let updateImagegallery=Object.assign({},imagegallery);
                updateImagegallery.photographs.push(decodeURIComponent(slide))
                //Object.assign(imagegallery, {'photographs': photographs})
                //imagegallery.photographs.push(decodeURIComponent(slide))
                //imagegallery.photographs = [...new Set(imagegallery.photographs)]
                this.updateGallery(updateImagegallery)
            },
            selectGalery(){

              let imagegallery=null;
              this.imagegalleries.forEach((item)=>{
                  if (item['@id']===this.selectedGallery){
                      imagegallery=item;
                      return true;
                  }
              });

              this.addToGallery(imagegallery,this.selectedSlide['id']);

            },
            showGallerySelectDialog(slide){
                this.selectedSlide=slide;
                this.gallerySelectDialog=true;
            },
            updateGallery (gallery) {
                //this.$store.commit('feeditem/list/isLoading', true)
                let ig = JSON.parse(JSON.stringify(gallery))
                if(typeof ig.retrosnaps!=='undefined') ig.retrosnaps=this.arrayToIris(ig.retrosnaps);
                if(typeof ig.subGalleries!=='undefined') ig.subGalleries=this.arrayToIris(ig.subGalleries);
                if(ig.parentGalleries) delete ig.parentGalleries;
                return fetch(ig['@id'], {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(ig)
                })
                    .then(response => response.json())
                    .then(() => {
                        this.gallerySelectDialog=false;
                        //window.console.log(data)
                    })
                    .catch((e) => {
                        //this.$store.commit('feeditem/list/error', e.message)
                        this.$store.error=e.message;
                        return e

                    })
            },
            arrayToIris(datas){
                let iriArray=[];
                datas.forEach((data)=>{
                    iriArray.push(data['@id']);
                });
                return iriArray;
            },
            rellaxRefresh () {
                if (this.rellax &&
                    typeof this.rellax == 'object' &&
                    typeof this.rellax.refresh != 'undefined') {
                    this.rellax.refresh()
                }
            },
            setActiveTouchEle (item) {
                //console.log('setActiveTouchEle',item)
                this.activeTouchEle = item
            },
            getAlbumForSearchParams(searchParams) {
                this.albumsForSearchParams=null;
                return fetch(this.entrypoint + '/feed_items?searchParameters=' + encodeURIComponent(searchParams), {
                    method: 'GET',
                    headers: new Headers({'Content-Type': 'application/json'}),
                })
                    .then(response => response.json())
                    .then((data) => {
                        //window.console.log(data);
                        if(typeof data['hydra:totalItems'] !=='undefined'){
                            this.albumsForSearchParams=data['hydra:totalItems'];
                            //window.console.log('this.albumsForSearchParams',this.albumsForSearchParams)
                        }

                    })
                    .catch((e) => {
                        window.console.error('Loading Albums was not successful for the following reason: ' + e)
                        return e
                    })

            },
            getRatioRounded(item){
                let ratio = 1;
                if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                    ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
                }else if(item.imageRatio ){
                    ratio = item.imageRatio;
                }
                if(ratio>1.5){
                    return 2;
                }else if(ratio<0.8){
                    return 0.5;
                }
                return 1;
            },
            getRatioClass(item){
                let miClass='';
                let ratio = 1;
                if(item.documentType==='ImageGallery'){
                    miClass=' mi-small' ;
                    if (item.showBig){
                        miClass=' mi-big';
                    }
                }else if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                    ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
                }else if(item.imageRatio ){
                    ratio = item.imageRatio;
                }
                if (typeof item.miClass !=='undefined') miClass=' mi-'+item.miClass;
                if (miClass==''){
                    if(ratio>1.5){
                        miClass=' mi-horizontal';
                    }else if(ratio<0.8){
                        miClass=' mi-vertical';
                    }else if(ratio === 1){
                    // fixes the case with equal width and height which returned ' mi-small ' and the image was squashed
                    miClass=' mi-vertical';
                    }else{
                        miClass=' mi-small';
                    }
                }
                return miClass;
            },
            getSizeSort(item){
                let miClass=this.getRatioClass(item);
                //window.console.log(miClass);
                switch (miClass){
                    case ' mi-big':
                        return 2;
                    case ' mi-horizontal':
                        return 2;
                    case ' mi-vertical':
                        return 1;
                    case ' mi-small':
                        return 1;
                }
            }



        }
    }
</script>
