<template>
    <div class='list-container'>
        <v-toolbar
            dense
            class="apptoolbar"
        >
            <router-link to="/feed">
                <img
                    src="@/assets/img/nimmerso_typeface.svg"

                    :alt="$t('app.Title')"
                    class="logo_nav_bar"
                />
            </router-link>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="showInAppInstallPromotionDialog"
                max-width="490"
                style="z-index: 1001"
            >
                <v-card>
                    <v-card-title class="text-h5 pb-10">{{ $t('app.installTitle') }}</v-card-title>
                    <v-card-text class="text-justify">
                        <img src="@/assets/img/screenshot_android_nimmerso.png" style="float: left; margin: 0px 15px 15px 0px;" />
                        {{ $t('app.installDescription') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="secondary"
                            text
                            @click="installDismiss()"
                        >
                            {{ $t('app.installDismiss') }}
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="primary"
                            text
                            @click="installAgree()"
                        >
                            {{ $t('app.installAgree') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <div class="background-image-intro" id="shadow">
            <div class="intro-content">
                    <div class="content-left">
                        <div class="time-image"></div>

                        <div class="panel_mobile">
                            <div class="text-mobile">
                                <div class="darken-bg">
                                </div>
                                <span class="intro-text-one">{{ $t('app.IntroTextOne') }}</span>
                                <br>
                                <span class="intro-text-two">{{ $t('app.IntroTextTwo') }}</span>
                                <br>
                                <span class="intro-text-number">10.000</span>
                                <br>
                                <span class="intro-text-three">{{ $t('app.IntroTextThree') }}</span>
                                <br>
                                <span class="intro-text-four">{{ $t('app.IntroTextFour') }}</span>
                            </div>
                        </div>
                        <div id="join-image-id" class="join-image" @click="scrollTo('section1')"></div>
                    </div>

                <!-- Right Column -->
                <v-col cols="8" md="6" sm="7"  lg="6" class="right-column">
                    <!-- Content for the right column -->
                    <div class="content-right">
                        <div class="panel_web">
                            <div class="border"></div>
                            <div class="text-web">
                                <p class="intro-text-one">{{ $t('app.IntroTextOne') }}</p>
                                <p class="intro-text-two">{{ $t('app.IntroTextTwo') }}</p>
                                <p class="intro-text-number">10.000</p>
                                <p class="intro-text-three">{{ $t('app.IntroTextThree') }}</p>
                                <p class="intro-text-four">{{ $t('app.IntroTextFour') }}</p>
                            </div>
                        </div>
                        <div  class="join-image-web" @click="scrollTo('section1')"></div>
                    </div>
                </v-col>
            </div>
        </div>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
<!--        <v-tooltip left >-->
<!--            <template v-slot:activator="{ on: onTooltip }">-->
<!--                <v-btn @click="goToFeed" icon-->
<!--                       class="closeWinButton"-->
<!--                       right-->
<!--                       fixed-->
<!--                       v-on="onTooltip">-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--            </template>-->
<!--            <span>{{ $t('app.Close') }}</span>-->
<!--        </v-tooltip>-->
        <div >
            <div v-if="!isLoading"
                 id="section1"
                 class="
        d-flex
        font-weight-light
        font-italic
        flex-column justify-center align-center custom-line-height"
            >
                <div id="title-parallax">
                    <span class="title-collections">Kulturerhalt</span>
                    <span class="subtitle-collections">durch Crowdfunding</span>
                    <v-btn class="mt-2" elevation="8" @click="openDialog">
                        Wie funktioniert das
                        <img  src="@/assets/img/QuestionMark.svg" alt="Custom Icon" width="24" height="24"/>
                    </v-btn>
                </div>
                <!-- Full Screen Dialog -->
                <v-dialog v-model="dialog"
                          hide-overlay transition="dialog-bottom-transition"
                          max-width="400"
                          persistent
                >
                    <v-card class="pa-6">
                        <v-card-text class="fo explanation" >
                            <p>{{ $t('app.explanation_1') }}</p>
                            <p>{{ $t('app.explanation_2') }}</p>
                            <p>{{ $t('app.explanation_3') }}</p>
                            <p>{{ $t('app.explanation_4') }}</p>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end pa-0">
                            <v-btn
                                icon
                                color="primary"
                                @click="closeDialog"
                                class="close-btn"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <div  v-if="!isLoading" class="container custom-border mt-8 mb-8 ">
                <div class="row justify-center align-center ">
                    <div class="image-container-info">
                        <img class="collection-presentative-image-info" src="@/assets/img/Waehle_eine_Sammlung.jpg">
                        <svg class="arrow-svg" width="50px" height="50px" viewBox="0 0 47 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;fill: #79bdfa;">
                           <path d="M11.032,2.569l30.616,30.616l-30.616,30.616l0,-61.232Z"/>
                        </svg>
                    </div>
                    <div
                        @click="navigateTo(item)"
                        v-for="item in sortedCollections"
                        class="col-auto custom-hover"
                        :key="item.id"
                    >
                        <v-img
                            class="collection-presentative-image"
                            :src="entrypoint+'/photograph_collections/'+item.thumbnail+'/display'"
                            min-height="300"
                            width="240"
                            max-height="calc(25vh - 24px)"
                            margin="auto"
                            cover
                        ></v-img>
                        <v-progress-linear
                            class="custom-progress-bar"
                            :value='(item.crowdfundingFunded / item.crowdfundingRequired) * 100'
                            height="20"
                            color="#79bdfa"
                        ></v-progress-linear>
                        <v-list-item-content class="headline-on-cover">
                            <v-list-item-title class="title-style">{{ item.headline }}</v-list-item-title>
                        </v-list-item-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.image-container-info{
    padding: 0 10px 20px 10px;
    position: relative;
    display: inline-block;
}
.arrow-svg {
    position: absolute;
    top: 45%;
    right: -6%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .arrow-svg {
        top: 90%;
        right: 40%;
        transform: translateX(50%);
        transform: rotate(90deg);
    }
}
.title-style{
    max-width: 240px !important;
}
.explanation{
    font-size: 23px;
}

#title-parallax{
    display: flex;
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
    height: 300px;
    overflow: hidden;
}
#shadow{
    transition: box-shadow 0.3s ease;
    box-shadow: inset 0px -110px 80px -10px #282c39, 0px 110px 80px -10px #282c39;

}
header.apptoolbar{
    z-index: 4 !important;
}
.close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
}
.v-progress-linear >>> .v-progress-linear__buffer{
    box-shadow:unset !important;
    background-image: unset !important;
}
.v-progress-linear>>>.v-progress-linear__background{
    background-color: unset!important;
    border-color: rgb(121, 189, 250)!important;
}
.v-progress-linear>>>.v-progress-linear__determinate{
    background-color: rgb(121, 189, 250) !important;
}
.title-collections{
    color:rgb(121, 189, 250);
    font-size: 45px;
    font-weight: normal;
    font-family: 'Courier New', monospace;

}
.custom-line-height {
    line-height: 1.1;
    height: 200px;


}

.subtitle-collections{
    color:rgb(121, 189, 250);
    font-size: 25px;
    font-weight: lighter;
}
@media screen and (max-width: 1024px) {
    .custom-border{
        border-image-width: 13rem;
        padding: 90px;
        padding-left: 60px;
        border-image-outset: 70px;
    }
    .container{
        max-width: 60vw!important;
    }
}
@media screen and (max-width: 960px) {
    .custom-border{
        border-image-width: 13rem;
        padding: 90px;
        padding-left: 60px;
        border-image-outset: 70px;
    }
    .container{
        max-width: 80vw!important;
    }
}
@media screen and (max-width: 770px) {
    .container{
        max-width: 55vw!important;
    }
    .custom-border{
        border-image-width: 13rem;
        padding: 70px;
        padding-left: 70px;
        border-image-outset: 90px;
    }
}
@media screen and (max-width: 600px) {
    .container{
        max-width: 70vw!important;
    }
    .custom-border{
        border-image-width: 10rem;
        padding: 70px;
        padding-left: 70px;
        border-image-outset: 60px;;

    }
}
@media screen and (max-width: 400px) {
    .container{
        max-width: 50vw!important;
    }
    .custom-border{
        border-image-width: 5rem;
        padding: 20px;
        padding-left: 20px;
        border-image-outset: 40px;

    }
    .title-collections{
        font-size: 28px;
    }
    .subtitle-collections{
        font-size: 25px;
    }

}
.container{
    max-width: 80vw!important;
}
</style>
<style>
@import "../../styles/intro-styles.css";
.image-wrapper {
    min-height: 25vh;
}
.list-container{
    background-color: #282C38;
    width: 100% !important;
    position: absolute;
    top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
}
.custom-hover {
    cursor: pointer;
    padding: 20px;
}
.custom-border{
    border-image: url("~@/assets/img/Beautiful_Art_Nouveau_border_bare_DONE.svg") 36% 37% round stretch;
    border-image-width: 19rem;
    border-image-outset: 80px;
    padding: 165px;

}


.shadow-img{
    border-color: #0A7EbE;
}
.headline-on-cover{
    color: rgb(121, 189, 250);
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
}
.custom-hover:hover .headline-on-cover{
    opacity: 1;
}

.custom-progress-bar {
    max-width: 300px;
    margin: 20px auto auto;
    border: black 1px solid;
    border-radius: 6px;
}
.collection-presentative-image {
    margin: auto;
    border: 1px solid  #0A7EbE; /* Example border style */
    border-radius: 4px;
    box-shadow:2px 4px 10px rgba(0,0,0,.5),-2px 4px 10px rgba(0,0,0,.5)
}
.collection-presentative-image-info {
    margin: auto;
    border: 1px solid  #b8ffaf;
    border-radius: 4px;
    box-shadow:2px 4px 10px rgba(0,0,0,.5),-2px 4px 10px rgba(0,0,0,.5);
    min-height:350px;
    width:240px;
    max-height: calc(25vh - 24px);
}
</style>
<script >
import {mapActions, mapGetters} from "vuex";
import {ENTRYPOINT} from "@/config/entrypoint";

export default {
    computed: mapGetters({
        error: 'photographcollection/list/error',
        items: 'photographcollection/list/items',
        isLoading: 'photographcollection/list/isLoading',
    }),
    watch: {
        items: function () {
            this.sortedCollections = Object.assign([], this.items)
            this.sortedCollections = this.sortedCollections
                .filter(item => item.status === 1)
                .sort((a, b) => {
                    // Check if crowdfundingFunded is non-null and then compare its values
                    const aHasFunded = a.crowdfundingFunded != null;
                    const bHasFunded = b.crowdfundingFunded != null;

                    if (aHasFunded && !bHasFunded) {
                        return -1; // a should come before b
                    }
                    if (!aHasFunded && bHasFunded) {
                        return 1; // b should come before a
                    }

                    // Both are either non-null or null, compare their values
                    if (a.crowdfundingFunded != null && b.crowdfundingFunded != null) {
                        if (a.crowdfundingFunded/a.crowdfundingRequired * 100 > b.crowdfundingFunded/b.crowdfundingRequired * 100) {
                            return -1; // a should come before b
                        }
                        if (a.crowdfundingFunded/a.crowdfundingRequired * 100  < b.crowdfundingFunded/b.crowdfundingRequired * 100) {
                            return 1; // b should come before a
                        }
                    }

                    // Fall back to internalRating if crowdfundingFunded values are equal or both are null
                    if (a.internalRating > b.internalRating) {
                        return -1; // a should come before b
                    }
                    if (a.internalRating < b.internalRating) {
                        return 1; // b should come before a
                    }
                    return 0; // They are equal
                });
        }
    },
    data() {
        return {
            deferredInstallPrompt: null,
            isMobile: false,
            showInAppInstallPromotionDialog: false,
            entrypoint: ENTRYPOINT,
            sortedCollections: [],
            crowdfundingValue: 0,
            dialog: false
        }
    },
    created () {
        this.getCollections('photograph_collections?page=1&order[dateCreated]=DESC&status=1')
        window.addEventListener('scroll', this.handleParallaxScroll)
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            this.deferredInstallPrompt = e;
            if (this.deferredInstallPrompt) {

                this.tourSteps.splice(2, 0, {
                    target: '[data-v-step="2"]',
                    content: 'Installiere ein Nimmerso-Icon auf deinem Smartphone, damit du immer sofort Nimmerso nutzen kannst.',
                });
            }
        })
    },

    methods:{
        ...mapActions({
            getCollections: 'photographcollection/list/default'
        }),
        // Opens the dialog
        openDialog() {
            this.dialog = true;
        },
        handleParallaxScroll(){
            document.getElementById("join-image-id").style.display='none'
            // const elementTitle = document.getElementById("title-parallax");
            // const elementStyle = document.getElementById("parallax-style");
            const shadow = document.getElementById("shadow");


            // Get the scroll position
            const scrollPosition = window.scrollY;


            if (scrollPosition>500){
                // elementTitle.style.height = 300 + 'px';
                // elementStyle.style.height = 0 + 'px';
            }
            else if (scrollPosition <= 500) {
                shadow.style.boxShadow = 'inset 0px -110px 50px -10px #282c39, 0px 80px 50px 80px #282c39'; // Default shadow color
                // elementTitle.style.height = 0 + 'px';
                // elementStyle.style.height = 100 + 'px';
            }
            if (scrollPosition<=100 && window.innerWidth <= 768){
                document.getElementById("join-image-id").style.display='block'
            }
        },
        // Closes the dialog
        closeDialog() {
            this.dialog = false;
        },
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
        showInAppInstallPromotion(){
            this.showInAppInstallPromotionDialog=true;

        },
        installDismiss(){
            this.showInAppInstallPromotionDialog=false;
        },
        scrollTo(sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        async installAgree(){
            this.showInAppInstallPromotionDialog=false;
            // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
            this.deferredInstallPrompt.prompt();
            // Find out whether the user confirmed the installation or not
            const { outcome } = await this.deferredInstallPrompt.userChoice;
            // The deferredPrompt can only be used once.
            this.deferredInstallPrompt = null;
            // Act on the user's choice
            if (outcome === 'accepted') {
                window.console.log('User accepted the install prompt.');
            } else if (outcome === 'dismissed') {
                window.console.log('User dismissed the install prompt');
            }
        },
        goToFeed() {
            this.$router.push('/feed');
        },
        navigateTo(item) {
            this.$router.push({name: 'PhotographCollectionShow', params: {id: item['@id']}})
        }
    },
    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
        window.removeEventListener('scroll', this.handleParallaxScroll);
    },
    mounted () {
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })

    }
}
</script>
